@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

html,
body {
    height: 100%;
}

#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

@layer base {
    :root {
        --roboto-slab: "Roboto Slab";

        --background: 0 0% 100%;
        --foreground: 0 0% 3.9%;

        --card: 0 0% 100%;
        --card-foreground: 0 0% 3.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 0 0% 3.9%;

        --primary: 0 0% 9%;
        --primary-foreground: 0 0% 98%;

        --secondary: 0 0% 96.1%;
        --secondary-foreground: 0 0% 9%;

        --muted: 0 0% 96.1%;
        --muted-foreground: 0 0% 45.1%;

        --accent: 0 0% 96.1%;
        --accent-foreground: 0 0% 9%;

        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 0 0% 98%;

        --border: 0 0% 89.8%;
        --input: 0 0% 89.8%;
        --ring: 0 0% 3.9%;

        --radius: 0.5rem;
        --primary: #000;
        --sky-blue: #00A6D6;
        --sky-blue-10: #E0F5FE;
        --sky-blue-20: #B9EEFE;
        --navy: #0C2340;
        --navy-40: rgba(12, 35, 64, 40%);
        --corn-yellow: #FFB81C;
        --corn-yellow-10: #FFF1D2;
        --palatinate-purple: #6F1D77;
        --jungle-green: #009B77;
        --jungle-green-10: #009B77;
        --dim-grey: #67676B;
        --stone-grey: #96969B;
        --silver-grey: #E5E5E5;
        --seasalt: #FAFAFA;
        --alice-blue: #EFF1F3;
        --subtext: #666666;
        --input-border: #525252;
    }

    .dark {
        --background: 0 0% 3.9%;
        --foreground: 0 0% 98%;

        --card: 0 0% 3.9%;
        --card-foreground: 0 0% 98%;

        --popover: 0 0% 3.9%;
        --popover-foreground: 0 0% 98%;

        --primary: 0 0% 98%;
        --primary-foreground: 0 0% 9%;

        --secondary: 0 0% 14.9%;
        --secondary-foreground: 0 0% 98%;

        --muted: 0 0% 14.9%;
        --muted-foreground: 0 0% 63.9%;

        --accent: 0 0% 14.9%;
        --accent-foreground: 0 0% 98%;

        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 0 0% 98%;

        --border: 0 0% 14.9%;
        --input: 0 0% 14.9%;
        --ring: 0 0% 83.1%;
    }
}

@layer base {
    * {
        @apply border-border;
    }

}
p,li {
    a {
        color: #00A6D6;
    }
}
.container {
    @media (max-width: 639px) {
        padding-left: 24px;
        padding-right: 24px;
    }
}
.dialog-custom {
    display: flex;
    align-items: safe center;
    justify-content: center;
    z-index: 1400;
}

.preloader {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.loaderContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.height100 {
    height: 100%;
}

.ldsEllipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 50px;
}

.ldsEllipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #00A6D6;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.ldsEllipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.ldsEllipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.ldsEllipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.ldsEllipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.845665 3.1515C1.04093 2.9495 1.35751 2.9495 1.55277 3.1515L5.99922 7.75127L10.4457 3.1515C10.6409 2.9495 10.9575 2.9495 11.1528 3.1515C11.348 3.35349 11.348 3.68099 11.1528 3.88299L6.35277 8.8485C6.259 8.9455 6.13183 9 5.99922 9C5.86661 9 5.73943 8.9455 5.64567 8.8485L0.845665 3.88299C0.650403 3.68099 0.650403 3.35349 0.845665 3.1515Z' fill='%2367676B'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: right 12px  center;
    background-size: 12px 12px;
}

.buttons-combination {
    display: flex;
    width: fit-content;
    flex-direction: row;
    padding: 20px;
    background-color: var(--palatinate-purple);
  }

  .buttons-combination__button span {
    display: inline-block;
    color: #67676b;
    line-height: 2;
    border: 1px solid #96969b;
    background-color: #eff1f3;
    border-radius: 2px;
    padding: 0 4px 2px 4px;
    box-sizing: border-box;
  }

.custom-vertical-scrollbar::-webkit-scrollbar-track
{
	background-color: transparent;
}

.custom-vertical-scrollbar::-webkit-scrollbar
{
	width: 4px;
	background-color: #F5F5F5;
}

.custom-vertical-scrollbar::-webkit-scrollbar-thumb
{
	background-color: #B9EEFE;
}
  
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

[data-radix-popper-content-wrapper]{
    z-index: 5555 !important;
}

.avatar-tooltip{
    .arrow{
        transform: translateX(-50%);
    }
    &[data-side="top"]{
        .arrow{
            bottom: -7px;
            transform: rotate(180deg) translateX(50%);
        }
    }
    &[data-side="bottom"]{
        .arrow{
            top: -7px;
        }
    }
}
